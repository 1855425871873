export const PrivateConfig = {
  revalidationSecret: process.env.REVALIDATION_SECRET,
}

export const PublicConfig = {
  Sanity: {
    token: process.env.NEXT_PUBLIC_SANITY_TOKEN ?? '',
    projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID ?? '',
    dataset: process.env.NEXT_PUBLIC_SANITY_DATASET ?? '',
    useCdn: process.env.NEXT_PUBLIC_USE_SANITY_CDN === 'true',
  },
}
